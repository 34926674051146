<template>
	<div>
		<Breadcrumb></Breadcrumb>
		<el-row class="" :gutter="20" type="flex">
		      <el-col :span="24">
		        <el-card class="mt-2">
		          <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">会员详情</h4>
		          <div class="d-flex align-items-center flex-wrap">
					  <el-divider content-position="left">基本信息</el-divider>
					  <el-row>
						  <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									单位名称：
								</div>
								<div class="flex-1">
									{{ info.name || '' }}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									单位性质：
								</div>
								<div class="flex-1">
									{{ info.dw_xz || '' }}
								</div>
							 </div>
						  </el-col>
					  </el-row>
                      <el-row>
						  <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									单位地址：
								</div>
								<div class="flex-1">
									{{ info.address || '' }}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 130px;">
									统一社会信用代码：
								</div>
								<div class="flex-1">
									{{ info.xy_code || '' }}
								</div>
							 </div>
						  </el-col>
                      </el-row>
                      <el-row>
						  <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									法人代表：
								</div>
								<div class="flex-1">
									 {{ info.legal_person || '' }}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									法人代表电话：
								</div>
								<div class="flex-1">
									 {{ info.l_phone || '' }}
								</div>
							 </div>
						  </el-col>
                      </el-row>
					<el-row>
					  <el-col :span="12">
						  <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								法人代表手机：
							</div>
							<div class="flex-1">
								{{ info.l_mobile || '' }}
							</div>
						  </div>
					  </el-col>
					  <el-col :span="12">
						 <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								法人代表传真：
							</div>
							<div class="flex-1">
								 {{ info.l_fax || '' }}
							</div>
						 </div>
					  </el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
						  <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								法人代表邮箱：
							</div>
							<div class="flex-1">
								 {{ info.l_email || '' }}
							</div>
						  </div>
						</el-col>
					    <el-col :span="12">
						 <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								联系人：
							</div>
							<div class="flex-1">
								 {{ info.work_person || '' }}
							</div>
						 </div>
					    </el-col>
					</el-row>
                    <el-row>
                    	<el-col :span="12">
                    	  <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								联系人电话：
							</div>
							<div class="flex-1">
								 {{ info.work_phone || '' }}
							</div>
                    	  </div>
                    	</el-col>
                        <el-col :span="12">
                    	 <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								联系人手机：
							</div>
							<div class="flex-1">
								 {{ info.work_mobile || '' }}
							</div>
                    	 </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                    	  <div class="w-100 flex align-center mb-2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								联系人职务：
							</div>
							<div class="flex-1">
								 {{ info.work_duties || '' }}
							</div>
                    	  </div>
                    	</el-col>
                        <el-col :span="12">
                    	    <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									联系人邮箱：
								</div>
								<div class="flex-1">
									 {{ info.work_email || '' }}
								</div>
                    	    </div>
                        </el-col>
                    </el-row>
					<el-row>
                        <el-col :span="12">
                    	  <div class="w-100 flex align-center mb-2" v-if="info.grade != 99 && info.grade != 2">
							<div class="flex align-center opacity-60" style="width: 100px;">
								营业额：
							</div>
							<div class="flex-1">
								 {{ info.cz ? info.cz+' 万元' : '' }}
							</div>
                    	  </div>
                    	</el-col>
                        <el-col :span="12">
                    	    <div class="w-100 flex align-center mb-2" v-if="info.grade != 99 && info.grade != 2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									注册资本：
								</div>
								<div class="flex-1">
									 {{ info.reg_money ? info.reg_money + ' 万元' : ''}}
								</div>
                    	    </div>
                        </el-col>
                    </el-row>
					<el-row>
					    <el-col :span="12">
					      <div class="w-100 flex align-center mb-2" v-if="info.grade != 99 && info.grade != 2">
					    	<div class="flex align-center opacity-60" style="width: 100px;">
					    		企业人数：
					    	</div>
					    	<div class="flex-1">
					    		 {{ info.people_num ? info.people_num + ' 人' : '' }}
					    	</div>
					      </div>
					    </el-col>
						<el-col :span="12">
						    <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									用户类型：
								</div>
								<div class="flex-1">
									 {{ info.grade_name || '' }}
								</div>
						    </div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
						    <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									用户角色：
								</div>
								<div class="flex-1">
									 {{ info.role_name || '' }}
								</div>
						    </div>
						</el-col>
					</el-row>
					<div class="w-100 flex flex-column" v-if="info.grade != 99 && info.grade != 2">
						<el-divider content-position="left">资质等级</el-divider>
						<el-row>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										承装等级：
									</div>
									<div class="flex-1">
										 {{ info.cz_grade || '' }}
									</div>
							    </div>
						    </el-col>
							<el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									承修等级：
								</div>
								<div class="flex-1">
									 {{ info.cx_grade || '' }}
								</div>
							  </div>
							</el-col>
						</el-row>
						<el-row>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										承试等级：
									</div>
									<div class="flex-1">
										 {{ info.cs_grade || '' }}
									</div>
							    </div>
						    </el-col>
							<el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									新能源：
								</div>
								<div class="flex-1">
									 {{ info.xny_grade == 1 ? '是' : '否' }}
								</div>
							  </div>
							</el-col>
						</el-row>
						<el-row>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										发电：
									</div>
									<div class="flex-1">
										 {{ info.fd_grade == 1 ? '是' : '否' }}
									</div>
							    </div>
						    </el-col>
							<el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									供电：
								</div>
								<div class="flex-1">
									 {{ info.gd_grade == 1 ? '是' : '否' }}
								</div>
							  </div>
							</el-col>
						</el-row>
						<el-row>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										售电：
									</div>
									<div class="flex-1">
										 {{ info.sj_grade == 1 ? '是' : '否' }}
									</div>
							    </div>
						    </el-col>
							<el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									设计：
								</div>
								<div class="flex-1">
									 {{ info.jl_grade == 1 ? '是' : '否' }}
								</div>
							  </div>
							</el-col>
						</el-row>
						<el-row>
						    <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									其他相关资质：
								</div>
								<div class="flex-1">
									 {{ info.other_aptitude || '' }}
								</div>
							  </div>
							</el-col>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										能源局资质：
									</div>
									<div class="flex-1">
										 {{ info.xny_aptitude || '' }}
									</div>
							    </div>
						    </el-col>
						</el-row>
						<el-divider content-position="left">历届职务</el-divider>
						<el-row>
						    <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 120px;">
									第一届所任职务：
								</div>
								<div class="flex-1">
									 {{ info.one_duties || '' }}
								</div>
							  </div>
							</el-col>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 120px;">
										第二届拟任职务：
									</div>
									<div class="flex-1">
										 {{ info.two_duties || '' }}
									</div>
							    </div>
						    </el-col>
						</el-row>
						<el-row>
						    <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 120px;">
									第三届拟任职务：
								</div>
								<div class="flex-1">
									 {{ info.three_duties || '' }}
								</div>
							  </div>
							</el-col>
						</el-row>
						<el-divider content-position="left">其他信息</el-divider>
						<el-row>
						    <el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									许可证编码：
								</div>
								<div class="flex-1">
									 {{ info.cz_code || '' }}
								</div>
							  </div>
							</el-col>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										许可证有效期：
									</div>
									<div class="flex-1">
										 {{ info.xkz_time || '' }}
									</div>
							    </div>
						    </el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 120px;">
										发电容量：
									</div>
									<div class="flex-1">
										 {{ info.fdrl || '' }}
									</div>
							    </div>
							</el-col>
						    <el-col :span="12">
						      <div class="w-100 flex align-center mb-2">
						    	<div class="flex align-center opacity-60" style="width: 120px;">
						    		会员到期时间：
						    	</div>
						    	<div class="flex-1">
						    		 {{ info.end_time || '' }}
						    	</div>
						      </div>
						    </el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
							  <div class="w-100 flex align-center mb-2">
								<div class="flex align-center opacity-60" style="width: 100px;">
									单位状态：
								</div>
								<div class="flex-1">
									<el-tag v-if="info.status === 1" size="mini" type="success">正常</el-tag>
									<el-tag v-if="info.status === 2" size="mini" type="danger">停用</el-tag>
								</div>
							  </div>
							</el-col>
						</el-row>
						<el-row>
						    <el-col :span="12">
							    <div class="w-100 flex align-center mb-2">
									<div class="flex align-center opacity-60" style="width: 100px;">
										备注：
									</div>
									<div class="flex-1">
										<div class="w-100 h-auto" v-html="info.remrks"></div>
							        </div>
							    </div>
						    </el-col>
						</el-row>
					</div>
					
		          </div>
		        </el-card>
		      </el-col>
		</el-row>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			Breadcrumb
		},
		data() {
			return {
				preUrl: 'user',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				id:'',
				info:{},
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
				this.getDetail()
			}
		},
		methods:{
			getDetail() {
			  this.axios.get(`/manage/${this.preUrl}/detail`, {
			    params: {
					id:this.id
				}
			  }).then(res => {
			    this.info = res.data
			  })
			},
		}
	}
</script>

<style>
</style>